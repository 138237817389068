import React from 'react';
import styles from './AvailableJobs.module.scss';
import { string } from 'prop-types';

interface Props {
  jobName: string;
  jobDescription: string;
  link: string;
  jobType: string;
}

const AvailableJobs: React.FC<Props> = ({ jobName, jobDescription, link, jobType }: Props) => {
  return (
    <div className={styles.singleJobWrap} id="single-job-wrap" data-job-type={jobType}>
      <div>
        <h2>{jobName}</h2>
        <span>{jobDescription}</span>
      </div>
      <a href={link}>Learn More</a>
    </div>
  );
};

export default AvailableJobs;
