import React from 'react';
import useViewport from '../../hooks/useViewport';
import styles from './EmployeesSlide.module.scss';
import quotes from '../../images/quotes.png';

interface Props {
  imageSrc?: string;
  authorName?: string;
  testimonialText?: string;
  employeeName?: string;
  employeePosition?: string;
}

const EmployeesSlide: React.FC<Props> = ({
  imageSrc,
  authorName,
  testimonialText,
  employeeName,
  employeePosition,
}: Props) => {
  return (
    <div className={styles.slideWrap}>
      <div className={styles.slideImgWrap}>
        <img src={imageSrc} alt={authorName} />
      </div>
      <div className={styles.slideContentWrap}>
        <img src={quotes} alt="" className={styles.slideQuotes} />
        <p className={styles.slideMainContent}>{testimonialText}</p>
        <div className={styles.sliderButton}>
          <h2>{employeeName}</h2>
          <p>{employeePosition}</p>
        </div>
      </div>
    </div>
  );
};

export default EmployeesSlide;
